@import '@angular/cdk/overlay-prebuilt.css';

/*
context menu start
compiled from './node_modules/@perfectmemory/ngx-contextmenu/src/assets/stylesheets/base.scss'
using https://jsonformatter.org/scss-to-css
*/
context-menu-content button[role='menuitem'] {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  /* Remove excess padding and border in Firefox 4+ */
}
context-menu-content button[role='menuitem']::-moz-focus-inner {
  border: 0;
  padding: 0;
}
:root {
  --ngx-contextmenu-focusable-border-bottom: 1px dotted #70757e;
  --ngx-contextmenu-font-family: sans-serif;
  --ngx-contextmenu-background-color: white;
  --ngx-contextmenu-border-radius: 4px;
  --ngx-contextmenu-border: 1px solid rgba(0, 0, 0, 0.18);
  --ngx-contextmenu-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
  --ngx-contextmenu-font-size: 14px;
  --ngx-contextmenu-margin: 2px 0 0;
  --ngx-contextmenu-min-width: 160px;
  --ngx-contextmenu-outline: 1px solid #70757e;
  --ngx-contextmenu-padding: 5px 0;
  --ngx-contextmenu-text-color: #70757e;
  --ngx-contextmenu-text-disabled-color: #8a909a;
  --ngx-contextmenu-item-arrow-left: '◀';
  --ngx-contextmenu-item-arrow-right: '▶';
  --ngx-contextmenu-item-background-hover-color: #f8f8f8;
  --ngx-contextmenu-item-separator-color: #8a909a;
  --ngx-contextmenu-item-separator-padding: 10px;
  --ngx-contextmenu-item-separator-width: 96%;
  --ngx-contextmenu-item-padding: 6px 20px;
  --ngx-contextmenu-item-text-hover-color: #5a6473;
}
context-menu-content:focus-visible {
  outline: var(--ngx-contextmenu-outline);
}
context-menu-content .ngx-contextmenu {
  font-family: var(--ngx-contextmenu-font-family);
}
context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu {
  list-style: none;
  outline: none;
  background-color: var(--ngx-contextmenu-background-color);
  color: var(--ngx-contextmenu-text-color);
  min-width: var(--ngx-contextmenu-min-width);
  padding: var(--ngx-contextmenu-padding);
  margin: var(--ngx-contextmenu-margin);
  font-size: var(--ngx-contextmenu-font-size);
  text-align: start;
  background-clip: padding-box;
  border: var(--ngx-contextmenu-border);
  border-radius: var(--ngx-contextmenu-border-radius);
  box-shadow: var(--ngx-contextmenu-box-shadow);
}
context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu .ngx-contextmenu--parent-menu:after {
  content: var(--ngx-contextmenu-item-arrow-right);
}
context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu > li {
  display: flex;
}
context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu > li:not([role='separator']) > * {
  flex: 1 1 auto;
}
context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu > li > [role='separator'] {
  border-width: 0 0 1px 0;
  border-bottom: 1px solid var(--ngx-contextmenu-item-separator-color);
  margin: var(--ngx-contextmenu-item-separator-padding) 2%;
  width: var(--ngx-contextmenu-item-separator-width);
}
context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu > li > [role='menuitem'] {
  padding: var(--ngx-contextmenu-item-padding);
}
context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu > li > [role='menuitem'] {
  outline: none;
  color: var(--ngx-contextmenu-text-color);
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
  white-space: nowrap;
}
context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu > li > [role='menuitem']:not(.disabled):not(.ngx-contextmenu--item-content-passive):hover, context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu > li > [role='menuitem']:not(.disabled):not(.ngx-contextmenu--item-content-passive).active {
  text-decoration: none;
  background-color: var(--ngx-contextmenu-item-background-hover-color);
  color: var(--ngx-contextmenu-item-text-hover-color);
}
context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu > li > [role='menuitem'].disabled {
  cursor: default;
}
context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu > li > [role='menuitem'].disabled, context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu > li > [role='menuitem'].disabled:hover, context-menu-content .ngx-contextmenu ul.ngx-contextmenu--dropdown-menu > li > [role='menuitem'].disabled.active {
  color: var(--ngx-contextmenu-text-disabled-color);
}
context-menu-content .ngx-contextmenu[dir='rtl'] ul.ngx-contextmenu--dropdown-menu .ngx-contextmenu--parent-menu:after {
  content: var(--ngx-contextmenu-item-arrow-left);
}
/*context menu end*/


.ng-select .ng-select-container {
  border-color: #ebedf2;
}

.is--cursor-pointer:hover {
  cursor: pointer;
}

ymaps.ymaps-2-1-68-search.ymaps-2-1-68-search_layout_normal.ymaps-2-1-68-searchbox__normal-layout {
  width: 100%;
}

.delivery-map-container-inner {
  width: 100%;
  height: 30rem;
  height: 40vh;
}

.m-dropdown__item {
  padding: 0 20px;
}

.m-dropdown__item:hover {
  color: #333;
  text-decoration: underline;
}

.m-dropdown__item:last-child {
  padding-bottom: 7px;
}

.m-dropdown__item:first-child {
  padding-top: 7px;
}

.m-dropdown .m-dropdown__wrapper .m-dropdown__body {
  padding: 10px 20px;
  font-size: 1rem;
}

.modal .modal-content .modal-footer--pad-right {
  padding-right: 0;
  border-top: 0;
}

/* .mydrp, .mydrp .headerclearbtn, .mydrp .headerokbtn {
  width: 100%!important;
} */

.hc-multiselect .selected-list .c-btn {
  padding: 0.65rem 1.25rem!important;
  box-shadow: none!important;
  line-height: normal;
  border: 1px solid #ebedf2!important;
}

.hc-multiselect .selected-list .c-list .c-token {
  line-height: normal;
}
.hc-multiselect .selected-list .c-list .c-token .c-label {
  font-size: 12px;
  text-transform: lowercase;
}

.hc-filters {
  height: 81px;
  position: relative;
  transition: all .3s;
}

.hc-filters .row {
  visibility: hidden;
  opacity: 0;
}

.hc-filters .row:first-child div:not(:first-child) {
  visibility: hidden;
  opacity: 0;
}

.hc-filters--advanced .row, .hc-filters .row:first-child,
.hc-filters--advanced .row:first-child div:not(:first-child) {
  visibility: visible;
  opacity: 1;
}

@media (min-width: 576px) {
  .hc-filters .row:first-child div:not(:first-child) {
    visibility: visible;
    opacity: 1;
  }
}

@media (max-width: 575px) {
  .modal .modal-content .modal-body {
    padding: 1.5rem;
  }
}

.hc-filters--advanced {
  height: auto;
  overflow: inherit;
}

.hc-filters__button-group {
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: right;
  width: 100%;
  background-color: #fff;
  z-index: 2;
  padding-top: 1px;
}

.hc-filters--advanced .hc-filters__button-group {
  visibility: hidden;
  opacity: 0;
}

.m-form__group--colored {
  background: #f9f9f9;
  padding-right: 2.2rem;
  padding-left: 1.5rem;
  margin-right: -2.2rem;
  margin-left: -2.2rem;
}

@media (max-width: 992px) {
  .is--responsive-table {
    display: block;
    overflow: hidden;
    overflow-x: auto;
    height: auto;
  }
}

.fc .fc-row .fc-content-skeleton table, .fc .fc-row .fc-content-skeleton td, .fc .fc-row .fc-helper-skeleton td {
  background: 0 0;
  border-color: transparent;
  height: 100%;
}

tr:first-child>td>.fc-day-grid-event {
  padding-top: 10px;
  padding-bottom: 10px;
}

.fc-day-number {
  font-size: 2rem;
}

.fc-button-primary, .fc-button-primary:disabled {
  color: #0e2230;
}

.table--no-border td {
  border-top: 0;
}

.courier-order {
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  background: rgba(0,0,0, .66);
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  padding: 1px;
  visibility: hidden;
  opacity: 0;
  transition: .3s ease;
  transition-delay: .3s;
}

.btn-brand:hover .courier-order {
  visibility: visible;
  opacity: 1;
  transition-delay: 0;
}

.hc-couriers {
  border-collapse: collapse;
  width: 100%;
}

.hc-couriers__list {
  display: flex;
  flex-wrap: wrap;
}

.hc-couriers__item {
  width: 14%;
  border: 1px solid #ebedf2;
  /* margin-bottom: -1px;
  margin-right: -1px; */
  position: relative;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hc-couriers__item--active {
  background-color: #34bfa3;
  color: #fff;
}


.day__date {
  font-size: 22px;
}

.hc-couriers__numb {
  position: absolute;
  font-size: 13px;
  font-weight: 600;
}

.hc-couriers__numb--left_top {
  left: 5px;
  top: 5px;
}

.hc-couriers__numb--right_top {
  top: 5px;
  right: 5px;
}

.hc-couriers__numb--right_bottom {
  bottom: 5px;
  right: 5px;
}

.hc-table--calendar {
  width: 100%;
  border-collapse: collapse;
}

.hc-table--calendar td,
.hc-table--calendar th {
  width: 14%;
  border: 1px solid #ebedf2;
}

.hc-table--calendar th {
  text-align: center;
}

.calendar { width: 100%; display: flex; flex-direction: column; }
.calendar > header {
  flex-grow: 7;
  display: flex; align-items: center; justify-content: center; }
.calendar .day-names {
  flex-grow: 5;
  display: flex; align-items: center;
}

.calendar .day-names div {
  color: #898b96;
  padding: 7px 0;
  flex-grow: 1;
  font-weight: 600;
  text-align: center;
  font-size: 11px;
  border-left: 1px solid #ebedf2;
  width: calc(100% * 1/7)
}

.calendar .day-names div:first-child {
  border-left-color: transparent;
}

.calendar .days {
  flex-grow: 88;
  display: flex; flex-direction: row; flex-wrap: wrap; }
.day {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    position: relative; height: 75px; width: calc(100% * 1/7); }

/* Appearance */
.calendar { text-transform: uppercase; border: 1px solid #ebedf2; }
.calendar > header { background: #FCFCFC; }
.calendar > header h1 { font-size: 2em; color: #333; }
.day-names {
  border-bottom: 1px solid #ebedf2;
 }
.calendar .day-names, .calendar .days { font-size: 0.825em; color: #666; }
.calendar .spacer  { background: #F5F5F5; }
.day {
  color: #ccc;
  border: 1px solid #ebedf2; border-width: 1px 0 0 1px; }
.calendar .days > div:nth-child(-n+7) { border-top-width: 0; }
.calendar .days > div:nth-child(7n+1) { border-left-width: 0; }
/* .calendar .days > div:nth-child(7n+6), .calendar .days > div:nth-child(7n+7) { background: #FEFEFE; } */
.calendar .day-number { float: right; padding: 1em; }
.day--has {
  color: #333;
}

.day--active {
  background-color: #34bfa3;
  color: #fff;
}

.day--active .hc-couriers__numb {
  color: #fff!important;
}

@media (min-width: 1280px) {
  .day {
    height: 120px;
  }
  .day__date {
    font-size: 30px;
  }
  .hc-couriers__numb {
    font-size: 16px;
  }
  .calendar .day-names div {
    font-size: 16px;
  }
}

@media (max-width: 1279px) {
  .m-badge {
    line-height: 15px;
    min-height: 15px;
    min-width: 15px;
  }
}

.btn-thin {
  padding-left: .25rem;
  padding-right: .25rem;
  border: 0;
}

.is--loading:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  border-top: 2px solid #07d;
  border-right: 2px solid transparent;
  border-radius: 50%;
  animation: m-loader-rotate 0.6s linear infinite;
  width: 1.4rem;
  height: 1.4rem;
  margin-top: -0.7rem;
  border-top-width: 2px;
  border-right-width: 2px;
  z-index: 1;
  right: 2rem;
}

.is--remove {
  position: absolute;
  right: 10px;
  top: 6px;
}

.cuppa-dropdown {
  margin-bottom: 10px;
}

.m-input--mini {
  width: 75px;
  display: inline-block;
  padding: 0.65rem 0.5rem;
}

.is--hidden {
  visibility: hidden;
  height: 0;
  padding: 0!important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 7px;
}

@media (min-width: 768px) {
  .hc-main-content {
    margin-bottom: 40px;
  }
}

@media (min-width: 993px) {
  .hc-main-content {
    width: calc(100% - 230px);
  }

  .m-aside-left--minimize .hc-main-content {
    width: calc(100% - 70px);
  }
}

.width-1440 {
  max-width: 1440px;
}

/* new modal styles */
.modal-header--brand {
  border: 0;
}

.modal-header--brand .modal-title {
  color: #0788FF;
  font-size: 30px;
  font-weight: 600;
}

.modal-header--brand .close {
  color: #0788FF;
  opacity: 1;
}

.modal-footer--brand {
  padding-right: 0;
  padding-left: 0;
  border-top: 0;
  justify-content: space-between;
}

.hc-button {
  font-size: 15px;
  font-weight: 500;
  border-radius: 5px;
  padding: 6px 17px;
  border: 0;
  height: 40px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
}

.hc-button + .hc-button {
  margin-left: 10px;
}

.hc-button:hover {
  cursor: pointer;
}

.hc-button:disabled {
  background: #F2F3F8;
  color: #B3B3B3;
  border-color: #E0E0E0;
}

.hc-button--primary {
  color: #fff;
  background: #0788FF;
}

.hc-button--success {
  background: #D5FFF0;
  color: #00C67F;
  padding: 8px 20px 7px;
  font-size: 14px;
  border: 2px solid #00C67F;
}

.hc-button--secondary {
  background: #D0E9FF;
  color: #0788FF;
  padding: 8px 20px 7px;
  font-size: 14px;
  border: 2px solid #0788FF;
}

.hc-button--danger {
  color: #F84445;
  background: #FFD7D8;
  border: 2px solid #F84445;
}

.mydrp .selectiongroup .selection,
.mydp .selectiongroup .selection {
  background: #F2F3F8;
  border-radius: 5px;
  border: 0;
  padding: 0 10px;
  height: 100%!important;
}

.mydrp .selectiongroup .btnpicker,
.mydrp .selbtngroup .btnclear,
.mydp .selectiongroup .btnpicker,
.mydp .selbtngroup .btnclear {
  background: #F2F3F8;
}

.mydrp .selectiongroup .selbtngroup,
.mydp .selectiongroup .selbtngroup {
  border-radius: 5px;
}

.mydrp .selectiongroup {
  height: 100%;
}

.mydrp,
.mydp {
  border: 0!important;
  height: 40px!important;
}

.m-badge--tag {
  margin-right: 10px;
}

.m-badge--tag .la {
  vertical-align: middle;
  margin-right: 5px;
  margin-bottom: 1px;
}

.hc-title {
  font-weight: 500;
  font-size: 30px;
  line-height: normal;
  color: #0788FF;
}

.hc-title--small {
  font-weight: 600;
  font-size: 22px;
}

/* Happy cake table style */
.hc-table {
  width: 100%;
  border-collapse: collapse;
}

.hc-table__cell {
  padding: 7px 10px;
}

.hc-table__row:not(.hc-table__row--head):hover {
  background-color: #E0E0E0;
  cursor: pointer;
}

.hc-table__row--head {
  border-bottom: 1px solid #E0E0E0;
}

.product-wrap {
  display: flex;
  flex-wrap: wrap;
}

.product-wrap__item {
  width: 100%;
  min-width: 240px;
}

@media(min-width: 576px) {
  .product-wrap__item {
    width: 50%;
  }
}

@media(min-width: 767px) {
  .product-wrap__item {
    width: 33%;
  }
}

@media(min-width: 1290px) {
  .product-wrap__item {
    width: 25%;
  }
}

.hc-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-image: url('/assets/images/sprite.svg');
}

.modal--check .modal-dialog {
  max-width: 380px;
  margin: 30px auto;
  position: relative;
}

.modal--check .modal-dialog::after,
.modal--check .modal-dialog::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 14px;
  left: 0;
  bottom: -14px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAOCAYAAAA8E3wEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABjSURBVHgBvc1RDYAwDEXRJwUJSKoEJNRpJSChsAT4YFnWbW1vcr8PVJU1J7nfUEpA5cPeAlGpsED0bGIBaMF2WHJA7ZgDOo4tooSVBlGCR0aU4FkHPRBRA2VE9kMZGT0oY6ILhwQzghbExowAAAAASUVORK5CYII=');
  background-repeat: repeat-x;
}

.modal--check .modal-dialog::before {
  bottom: initial;
  top: -14px;
  transform: rotate(180deg);
}

.modal--check .modal-content {
  border-radius: 0;
  padding: 15px 10px;
  box-shadow: none;
}


@media print {
  html {
    height: auto !important;
  }

  @page {
    size: auto;
    margin: 0;
  }

  html, body {
    background: transparent !important;
    box-shadow: none !important;
    text-shadow: none !important;
    position: static !important;
    color: #000 !important;
    font-family: arial, sans-serif;
  }

  body {
    width: 76mm;
    margin: 0;
    display: block;
  }

  body * {
    font-size: 11px !important;
    background: transparent !important;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  .m-aside-left, .m-footer, .m-header, .m-subheader, .m-content, #toast-container, .modal {
    display: none !important;
  }

  .m-body, .m-header--fixed .m-body, .m-body .m-wrapper, .m-page {
    margin: 0 !important;
    padding: 0 !important;
    display: initial !important;
    flex: none !important;
  }

  app-home {
    visibility: hidden !important;
  }
}

.report-filters {
  display: flex;
  flex-wrap: wrap;
}

.report-filters div.filter {
  margin-right: 1rem;
}

.filter .limit {
  min-width: 5rem;
}


/* TABLE FIFED */
.block-table-scroll{overflow: auto; height: 600px;}
.block-table-scroll table td{word-wrap: break-word;}
.block-table-scroll table{position: relative;}
.block-table-scroll table thead{
  position: sticky;
  z-index: 1;
}
.fixed-head-oline th{top: 0px;}
.fixed-head-tline th{top: 26px;}
.fixed-head-one{left: 0px;z-index: 3;}
.fixed-head-two{left: 102px;z-index: 3;}
.fixed-head-three{left: 219px;z-index: 3;}
.tbody-fixed-one{
  position: sticky;
  left: 0px;
  background: #ecedf7;
  font-weight: 700;
}
.tbody-fixed-two{
  position: sticky;
  left: 102px;
  background: #ecedf7;

}
.tbody-fixed-three{
  position: sticky;
  left: 221px;
  background: #ecedf7;
}
.table-footer-fixed th{
  position: sticky;
  bottom: 0px;
  background: #ecedf7;
}
